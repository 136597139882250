import React from 'react'
import Layout from '../components/layout'

class Contact extends React.Component {
  render(){
    return (
      <Layout>
        <div id="main" className="alt margin-small">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h1>Información de contacto</h1>
              </header>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Contact